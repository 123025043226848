<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import { axios } from "@/utils/axios";

import VueRecaptcha from "vue3-recaptcha2";
import AppCard from "@/components/AppCard";
import AppCardForm from "@/components/Card/AppCardForm";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import ResultsMultiple from "@/views/Guest/QuickPayment/ResultsMultiple.vue";
//
import useApi from "@/composables/useApi";
const { user,service, invoice } = useApi();
//
const route = useRoute();
const invoicesData = ref([]);
const serviceData = ref({});
const key = ref(0);
const serviceNumber = ref("");
const recaptcha = ref(false);
const hasError = ref(false);
const siteKey = process.env.VUE_APP_RECAPTCHA_KEY;
const list_to_be_paid = ref([]);
const visible = ref(false);

const verifyRecaptcha = (response) => {
  if (response != "") recaptcha.value = true;
};

const loading = computed(() => {
  return store.APP_LOADING;
});

const bloquear = computed(() => {
  return serviceNumber.value === "" || loading.value || !recaptcha.value;
});

const connectionPublicUser = async () => {
  try {
    const data = await user.publicUser();
    return data;
  } catch (error) {
    console.log(error);
    hasError.value = true;
    throw error;
  }
}

const loadService = async (id) => {
  try {
    const data = await service.getServiceByNumber(id.toUpperCase());
    return data.payload[0];
  } catch (error) {
    console.log(error);
  }
};

const loadPaymentHistory = async (service_id) => {
  const data = await invoice.getPaymentHistoryInvoicesByService(service_id);
  return data;
};

const loadInvoices = async (service_id) => {
  const data = await invoice.listInvoicesByService(service_id);
  let result = data.payload.filter(invoice => ["not_paid", "partial"].includes(invoice.payment_state));
  const invoices_in_payment_history = await loadPaymentHistory(service_id);
  result.sort((a, b) => {
    // First sort by due date descending
    const dateCompare = new Date(b.invoice_date_due) - new Date(a.invoice_date_due);
    if (dateCompare !== 0) return dateCompare;
    // If dates are equal, sort by id ascending
    return a.id - b.id;
  });
  result = result.map(invoice => ({
    ...invoice,
    state_to_pay: true,
    payment_status: invoices_in_payment_history.includes(invoice.id) ? true : false
  }));
  result = result.filter(invoice => !invoices_in_payment_history.includes(invoice.id));
  return result;
};

const submitForm = async () => {
  try {
    console.log("Submit..");
    store.APP_LOADING = true;
    const connectUser = await connectionPublicUser(serviceNumber.value);
    serviceData.value = await loadService(serviceNumber.value);
    invoicesData.value = await loadInvoices(serviceData.value.id);
    list_to_be_paid.value = invoicesData.value;
    if (list_to_be_paid.value.length > 0) {
      visible.value = true;
    }
    key.value = Math.random() * 10;
  } catch (error) {
    console.error(error);
    hasError.value = true;
  } finally {
    store.APP_LOADING = false;
    recaptcha.value = "";
  }

};

onMounted(() => {
  serviceNumber.value = route?.params?.id || "";
  store.APP_LOADING = false;
});
</script>
<template lang="pug">
.quick
  h1  * 
  AppCard
    AppFormInput(type="text" v-model:value="serviceNumber" :label="$t('quickpayment.service_number')" :disabled="loading")
    .text-center
    vue-recaptcha.g-recaptcha(:site-key="siteKey" v-if="!loading" size="normal" theme="light" @verify="verifyRecaptcha")
    .row
    AppButton.col-span-2(:disabled="bloquear" :loading="loading" :label="$t('quickpayment.continue')" @click="submitForm")
  ResultsMultiple(:key="key" :serviceNumber="serviceNumber" :hasError="hasError" v-model:invoices="list_to_be_paid" :service="serviceData" :showOption="true" v-show="visible")
  AppCard.mt-4.text-center
    span.label
      | {{$t('quickpayment.caption')}}
  AppCard.mt-4.text-center
    span.label
      | {{$t('quickpayment.info')}}
</template>

<style lang="scss" scoped>
.quick {
  @apply p-4;
  @apply mx-auto;
  @screen lg {
    @apply w-3/4;
  }
  @screen xl {
    @apply w-1/2;
  }
}

.g-recaptcha {
  @apply flex;
  @apply justify-center;
  @apply mb-1;
  @apply transform;
  @apply scale-75;
  @screen md {
    @apply scale-100;
  }
}

.label {
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
